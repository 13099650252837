import { useMemo, useState, useEffect } from 'react';
import { UseInfiniteQueryResult } from '@tanstack/react-query';
import { ListInfoPagesToReadQuery } from '../../../graphql/operations';
import { groupBy } from '../../utils/groupBy';

export type useInfoPageCategoriesPropTypes = {
  infoPages: UseInfiniteQueryResult<ListInfoPagesToReadQuery, unknown>;
};

export const useInfoPageCategories = ({ infoPages }: useInfoPageCategoriesPropTypes) => {
  const defaultCategoryName = useMemo(() => {
    if (
      infoPages.data?.pages[0].infoPageList.infoPages.every(
        infoPage =>
          infoPage.infoPageCategoryId === null || infoPage.infoPageCategoryId === undefined,
      )
    ) {
      return 'Alle';
    }
    return 'Sonstiges';
  }, [infoPages.data]);

  const infoPagesGroupedByCategory = useMemo(() => {
    if (infoPages.data) {
      return groupBy(
        infoPages.data?.pages[0].infoPageList.infoPages,
        infoPage => infoPage.infoPageCategoryName ?? defaultCategoryName,
      );
    }
  }, [infoPages.data]);

  const categories = useMemo(() => {
    if (infoPagesGroupedByCategory && categoryCount !== 1) {
      const set = Object.keys(infoPagesGroupedByCategory).sort((c1, c2) => c1.localeCompare(c2));
      return [...set.filter(category => category !== defaultCategoryName), defaultCategoryName];
    }
  }, [infoPages.data]);

  const categoryCount = useMemo(() => {
    return categories?.length ?? 0;
  }, [infoPages.data]);

  const [isCollapsed, toggleCollapse] = useState<boolean[]>([]);

  useEffect(() => {
    toggleCollapse(Array(categoryCount).fill(false));
  }, [infoPages.data]);

  const handleCollapseAccordion = (index: number) => {
    if (index !== -1) {
      const modifiedState = [...isCollapsed];
      modifiedState[index] = !modifiedState[index];
      toggleCollapse(modifiedState);
    }
  };

  return {
    handleCollapseAccordion,
    isCollapsed,
    toggleCollapse,
    categoryCount,
    categories,
    infoPagesGroupedByCategory,
  };
};
