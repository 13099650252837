const config = {
  expo: {
    ios: {
      requireFullScreen: true,
    },
    owner: 'likwidcare-org',
    jsEngine: 'hermes',
    plugins: [
      'expo-system-ui',
      'expo-local-authentication',
      'expo-navigation-bar',
      'expo-splash-screen',
      'expo-font',
      'expo-localization',
      'expo-secure-store',
      [
        'expo-document-picker',
        {
          iCloudContainerEnvironment: 'Production',
        },
      ],
      [
        'expo-build-properties',
        {
          android: {
            compileSdkVersion: 34,
            targetSdkVersion: 34,
            buildToolsVersion: '34.0.0',
          },
          ios: {
            deploymentTarget: '16.1',
          },
        },
      ],
      [
        'expo-media-library',
        {
          photosPermission:
            'Von Ihnen ausgewählte Fotos können Sie als Profilbild nutzen oder mit anderen Mitarbeitenden über die den Chat oder in News teilen.',
          savePhotosPermission:
            'Dadurch können Sie Fotos auf Ihrem Gerät speichern, wodurch die Fotos für andere Anwendungen zur Verfügung stehen',
          isAccessMediaLocationEnabled: true,
        },
      ],
      [
        'expo-screen-orientation',
        {
          initialOrientation: 'DEFAULT',
        },
      ],
    ],
    privacy: 'unlisted',
    orientation: 'portrait',
    primaryColor: '#fafafa',
    updates: {
      fallbackToCacheTimeout: 0,
    },
    assetBundlePatterns: ['**/*'],
    web: {
      favicon: './assets/expo/favicon.png',
    },
    userInterfaceStyle: 'automatic',
  },
};

module.exports = { config };
