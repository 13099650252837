const versionNumber = 18;
const versionCode = '1.2.2';
const packageId = 'com.likwidcare.arcus'; // @todo make sure android package id matches with this string
const termsOfServiceVersion = '2.1.0';
const iosStoreUrl = 'https://apps.apple.com/app/arcus/id6466809642'; // @todo put correct app store url here
const androidStoreUrl = 'https://play.google.com/store/apps/details?id=com.likwidcare.arcus';
const scheme = 'arcus';

/**
 * expo config for arcus
 * @type ExpoConfig
 */
const config = {
  expo: {
    name: 'ARCUS Mitarbeiter*innen App',
    description:
      'Die App bietet ARCUS Mitarbeiter*innen Zugang zu aktuellen Neuigkeiten und wichtigen Informationen rund um das ARCUS Sozialnetzwerk.',
    slug: 'arcus',
    scheme,
    version: versionCode,
    notification: {
      icon: './assets/expo/arcus/push_icon.png',
      iosDisplayInForeground: true,
      androidMode: 'collapse',
      androidCollapsedTitle: '#{unread_notifications} ungelesene Nachrichten',
    },
    android: {
      adaptiveIcon: {
        backgroundImage: './assets/expo/arcus/background.png',
        foregroundImage: './assets/expo/arcus/foreground.png',
      },
      versionCode: versionNumber,
      package: packageId,
      permissions: [
        'CAMERA',
        'CAMERA_ROLL',
        'WRITE_EXTERNAL_STORAGE',
        'READ_EXTERNAL_STORAGE',
        'RECORD_AUDIO',
        'USE_BIOMETRIC',
        'USE_FINGERPRINT',
        'DETECT_SCREEN_CAPTURE',
      ],
      googleServicesFile: './google-services-arcus.json',
    },
    ios: {
      buildNumber: versionNumber.toString(),
      bundleIdentifier: packageId,
      infoPlist: {
        NSCameraUsageDescription: 'Auf die Kamera zugreifen, um ein Profilbild aufzunehmen.',
        NSPhotoLibraryUsageDescription: 'Auf die Kamera zugreifen, um ein Profilbild hochzuladen.',
        NSMicrophoneUsageDescription:
          'Auf das Mikrofon zugreifen, um Sprachnachrichten aufzuzeichnen.',
        NSFaceIDUsageDescription:
          'Der User kann Face ID oder Touch ID verwenden, um sich in der App anzumelden.',
        UIBackgroundModes: ['remote-notification'],
        LSApplicationQueriesSchemes: ['tel', 'telprompt'],
      },
      config: {
        usesNonExemptEncryption: false,
      },
      supportsTablet: true,
      usesIcloudStorage: true,
    },
    plugins: [
      [
        '@sentry/react-native/expo',
        {
          organization: 'luci-gmbh',
          project: 'arcus',
          url: 'https://sentry.io/',
        },
      ],
    ],
    extra: {
      tenantName: 'arcus',
      appName: 'arcus',
      eas: {
        projectId: '7d08961d-1aa6-4602-9920-89eff2436adc',
      },
      sentryDsn:
        'https://64d2604374d1d1b6a09cfe8a067315ad@o1050914.ingest.sentry.io/4505907486523392',
    },
    splash: {
      image: './assets/expo/arcus/splash-preview.png',
      resizeMode: 'cover',
    },
    icon: './assets/expo/arcus/icon.png',
  },
};

module.exports = {
  config,
  versionNumber,
  versionCode,
  packageId,
  termsOfServiceVersion,
  iosStoreUrl,
  androidStoreUrl,
  scheme,
};
