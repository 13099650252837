import 'react-native-get-random-values';
import 'react-native-gesture-handler'; // must be top
import 'expo-dev-client';
import '@azure/core-asynciterator-polyfill';
import 'setimmediate'; // temp. fix for: https://github.com/software-mansion/react-native-reanimated/issues/4140

import * as React from 'react';
import * as Sentry from '@sentry/react-native';

import {
  AppStatusProvider,
  LegalProvider,
  PreferencesProvider,
  SupportProvider,
  TenantProvider,
} from './src/hooks/providers';

import { ActionSheetProvider } from '@expo/react-native-action-sheet';
import ErrorBoundary from 'react-native-error-boundary';
import { ErrorBoundaryFallback } from './src/screens/ErrorBoundaryFallback';
import { LogBox, Platform, useColorScheme } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { Provider as PaperProvider } from 'react-native-paper';
import { QueryClientProvider } from '@tanstack/react-query';
import { StatusBar } from 'expo-status-bar';
import { baseTheme, darkTheme } from './src/styles/theme';
import { createNavigatorTheme } from './src/styles/createNavigatorTheme';
import { startUp } from './src/utils/startup';
import { useTenant } from './src/hooks/tenant/retrieval';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { useThemeStore } from './src/stores/ThemeStore';
import { useNotificationResponseListener } from './src/hooks/useNotificationResponseListener/useNotificationResponseListener';
import { createLinkingConfiguration } from './src/utils';
import { AppNavigator } from './src/router/AppNavigator';
import { RootSiblingParent } from 'react-native-root-siblings';
import * as SplashScreen from 'expo-splash-screen';
import * as SystemUI from 'expo-system-ui';
import tinycolor from 'tinycolor2';
import { GestureHandlerRootView } from 'react-native-gesture-handler';

const linkingConfiguration = createLinkingConfiguration();

require('moment/locale/de.js');
//https://github.com/tannerlinsley/react-query/issues/1259
LogBox.ignoreLogs(['Setting a timer']);

const startup = startUp();

const SentryWeb = ({ children }: { children: React.ReactNode }) => {
  return <>{children}</>;
};

SplashScreen.preventAutoHideAsync();

function App(): React.ReactNode {
  const [isNavigationReady, setIsNavigationReady] = React.useState(false);
  const { tenant, isRetrievingTenant, fetchTenantAndUpdateState, setTenant } = useTenant();
  const { getColorScheme } = useThemeStore();
  const systemColorScheme = useColorScheme();
  const colorScheme = getColorScheme(systemColorScheme);
  const isDarkTheme = colorScheme === 'dark';
  const theme = isDarkTheme ? darkTheme : baseTheme;
  const SentryComponent = Platform.OS === 'web' ? SentryWeb : Sentry.TouchEventBoundary;
  useNotificationResponseListener(isNavigationReady);

  const darkThemePrimaryColorPreference = tenant?.theme?.color400;
  const lightThemePrimaryColorPreference = tenant?.theme?.color600;
  const hardCodedPrimaryColorPreference = theme.customColors.primary;

  const determinePrimaryColor = () => {
    if (isDarkTheme && darkThemePrimaryColorPreference) {
      return darkThemePrimaryColorPreference;
    }
    if (lightThemePrimaryColorPreference) {
      return lightThemePrimaryColorPreference;
    }
    return hardCodedPrimaryColorPreference;
  };

  const primaryColor = React.useMemo(
    () => determinePrimaryColor(),
    [
      isDarkTheme,
      darkThemePrimaryColorPreference,
      lightThemePrimaryColorPreference,
      hardCodedPrimaryColorPreference,
    ],
  );

  const accentColor = tinycolor(primaryColor).lighten(20).toHexString();

  const adaptedTheme = {
    ...theme,
    colors: {
      ...theme.colors,
      primary: primaryColor,
      accent: accentColor,
    },
    customColors: {
      ...theme.customColors,
      primary: primaryColor,
      accent: accentColor,
    },
  };

  const navigatorTheme = createNavigatorTheme(adaptedTheme, colorScheme === 'dark');

  React.useEffect(() => {
    if (Platform.OS !== 'web') {
      void SystemUI.setBackgroundColorAsync(theme.customColors.background);
    }
  }, [colorScheme, theme.customColors.background]);

  return (
    <SentryComponent>
      <SafeAreaProvider>
        <GestureHandlerRootView style={{ flex: 1 }}>
          <PaperProvider theme={adaptedTheme}>
            <QueryClientProvider client={startup.queryClient}>
              <RootSiblingParent>
                <ErrorBoundary
                  FallbackComponent={error => (
                    <ErrorBoundaryFallback error={error} theme={adaptedTheme} />
                  )}
                >
                  <TenantProvider
                    tenant={tenant}
                    setTenant={setTenant}
                    isRetrievingTenant={isRetrievingTenant}
                    fetchTenantAndUpdateState={fetchTenantAndUpdateState}
                  >
                    <PreferencesProvider>
                      <SupportProvider>
                        <AppStatusProvider>
                          <LegalProvider>
                            <ActionSheetProvider>
                              <NavigationContainer
                                linking={Platform.OS === 'web' ? undefined : linkingConfiguration}
                                onReady={() => setIsNavigationReady(true)}
                                theme={navigatorTheme}
                              >
                                <AppNavigator />
                              </NavigationContainer>
                            </ActionSheetProvider>
                          </LegalProvider>
                        </AppStatusProvider>
                      </SupportProvider>
                    </PreferencesProvider>
                  </TenantProvider>
                </ErrorBoundary>
              </RootSiblingParent>
            </QueryClientProvider>
          </PaperProvider>
        </GestureHandlerRootView>
        <StatusBar backgroundColor={adaptedTheme.colors.background} style={colorScheme === 'dark' ? 'light' : 'dark'} />
      </SafeAreaProvider>
    </SentryComponent>
  );
}

export default App;
